.header {
    background-color: #3498db;
    color: #ecf0f1;
    border: 2px solid #2c3e50;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 5px;
}

.logoContainer, .navLinksContainer {
    flex: 0; /* Adjusted to not grow */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.titleContainer {
    flex: 2; /* Adjusted to allow it to grow and take more space */
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2em;
    margin: 0;
    text-align: center;
}

.navLinks {
    display: flex;
    gap: 20px;
}

.navLink {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.logo {
    height: 50px;
    width: 50px;
}
.flagIcon {
    width: 30px; /* adjust as needed */
    height: 30px; /* maintain aspect ratio */
    cursor: pointer;
  }
