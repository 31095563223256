/* mainBoxStyles.module.css adjustments (example) */
.mainBox {
  margin: 5px;
  background-color: #3498db;
  border: 2px solid #2c3e50;
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-bottom: 0px;
  justify-content: center;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: calc(100vh - 10px); /* Changed from 600px to 100vh - 10px */
}

.box {
  border: 2px solid #2c3e50;
  padding: 20px;
  background-color: #ffffff;
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  max-width: none;
  width: calc(100% - 20px);
  margin: 10px;
}

.heading {
  color: #3498db;
  margin-bottom: 20px;
  text-align: center;
  flex: 1 0 100%;
  font-size: 1.5em;
}

.heading2 {
  margin-bottom: 20px;
  text-align: center;
  flex: 1 0 100%;
  font-size: 1.2em;
}

.entryBox {
  display: table;
  width: 100%; /* Adjust as needed */  
}

.entryBox th, .entryBox td {
  white-space: nowrap;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease; /* Adjust as needed */
  text-align: left;
  word-break: break-all;
  background-color: #f9f9f9;
}
.entryBox td:hover {
  background-color: #f5f5f5; /* Change as needed */
}

.scrollableTable {
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .entryBox {
    display: block;
    width: 100px; /* Adjust as needed */
  }
}

.label, .value {
  text-align: left;
  line-height: 1.5; /* Adjust as needed */
  padding: 0; /* Adjust as needed */
}

.noData {
  color: #2c3e50;
  text-align: center;
  margin-top: 30px;
  font-size: 1.2em;
}
