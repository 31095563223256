.mainBox {
    margin: 5px;
    background-color: #3498db;
    border: 2px solid #2c3e50;
    color: #2c3e50;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-bottom: 0px;
    min-height: calc(100vh - 10px); /* Changed from 600px to 100vh - 10px */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: flex-start; /* Aligns items to the top */

  }

.menu {
    display: flex;
    justify-content: center; /* Centers items horizontally */
    width: 100%; /* Takes up the full width of its container */
}