/* HomePage.css */


.mainBox {
  margin: 5px;
  background-color: #3498db;
  border: 2px solid #2c3e50;
  color: #2c3e50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-bottom: 0px;
  justify-content: center;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: calc(100vh - 10px); /* Changed from 600px to 100vh - 10px */
  padding: 10px;
}

.boxRow {
  column-count: 3;
  column-gap: 10px;
}

.boxItem {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px; /* Add vertical spacing between items */
}

@media screen and (max-width: 2000px) {
  .boxRow {
    column-count: 2; /* On medium screens, items are displayed in two columns */
  }
}

@media screen and (max-width: 900px) {
  .boxRow {
    column-count: 1; /* On small screens, items stack vertically */
  }
}

.box {
  border: 2px solid #2c3e50;
  padding: 10px; /* Moved padding from .boxItem to here */
  background-color: #ffffff;
  color: #2c3e50;
  margin: 0px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.headerTextBox {
  border: 2px solid #2c3e50;
  padding: 10px;
  background-color: #ffffff;
  color: #2c3e50;
  margin: 0; /* Change this */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.entryBox {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.heading {
  color: #3498db;
  margin-bottom: 15px;
  text-align: center;
}