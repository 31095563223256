

    .button {
        width: auto;
        padding: 10px;
        background-color: #3498db; /* Blue button */
        color: white;
        border: none;
        border-radius: 4px; /* Rounded corners for button */
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for button */
    }
    
    .button:hover {
        /* Separate style for hover effect */
        background-color: #2980b9;
    }



    .button2 {
        width: auto;
        padding: 10px;
      background-color: hsl(0, 0%, 0%); /* Blue button */
        color: white;
        border: none;
        border-radius: 4px; /* Rounded corners for button */
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for button */
        min-width: 80px;
    }

