

  .entryBox th.courseCodeLabel, .entryBox td.course_codeValue {
    width: 50px; /* Adjust as needed */
  }
  
  .entryBox th.courseNameLabel, .entryBox td.courseNameValue {
    width: 250px; /* Adjust as needed */
  }
  
  .entryBox th.creditsLabel, .entryBox td.creditsValue {
    width: 35px; /* Adjust as needed */
  }
  
  .entryBox th.gradeLabel, .entryBox td.gradeValue {
    width: 35px; /* Adjust as needed */
  }
  
  .entryBox th.typeLabel, .entryBox td.typeValue {
    width: 35px; /* Adjust as needed */
  }
  
  .entryBox th.completionDateLabel, .entryBox td.completion_date {
    width: 50px; /* Adjust as needed */
  }