.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    background-color: #ecf0f1;
    width: auto;
    border: 1px solid #bdc3c7;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex; /* Added */
    flex-direction: column; /* Added */
    align-items: center; /* Added */
}

.icon {
    width: 25px;
    height: 25px;
    margin-bottom: 10px; /* Added */
    cursor: pointer;
}

.button {
    background-color: #3498db;
    color: #ecf0f1;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #2980b9;
}