/* loginBox.module.css */


.loginBox {
    border: 2px solid #2c3e50;
    padding: 20px;
    background-color: #ffffff;
    color: #2c3e50;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 100%;
}

@media (max-width: 767px) {
    .loginBox {
      min-width: 80%; /* adjust as needed */
    }
  }
  
  @media (min-width: 768px) {
    .loginBox {
      min-width: 400px;
    }
  }
  

.heading {
    color: #3498db;
    margin-bottom: 15px;
    text-align: center;
}

.input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    box-sizing: border-box;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
}
.logo {
  width: 20px;
  height: 20px;
}
