
body {
  margin: 0;
  padding: 0;
}

.mainBox {
    margin: 5px;
    background-color: #3498db;
    border: 2px solid #2c3e50;
    color: #2c3e50;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding-bottom: 10px;
    justify-content: center;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    min-height: calc(100vh - 10px); /* Changed from 600px to 100vh - 10px */
  }